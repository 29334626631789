import React, { Fragment } from "react";
import "./Clients.css";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import Client1 from "../../Assets/the-pinnacle-group.png";
// import Client2 from "../../Assets/images-removebg-preview.png";
import Client3 from "../../Assets/eatsbee.png";
import Client4 from "../../Assets/source.png";
import Client5 from "../../Assets/upwork.png";
import { Fade } from "react-awesome-reveal";


export default function Clients() {
  return (
    <Fragment>
      <Box className="clientsMainContainer">
        <Box className="m-scroll">
            <Fade triggerOnce='true'>
          <Grid container rowSpacing={4} columnSpacing={5}>
            <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mr:'0' }}
            >
              <img
                src={Client1}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c1"
              />
            </Grid>
            {/* <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client2}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c2"
              />
            </Grid> */}
            <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client3}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c3"
              />
            </Grid>
            <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client4}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c4"
              />
            </Grid>
            <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client5}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c5"
              />
            </Grid>
          </Grid>
            </Fade>
          {/* <span >
                  <img
                    src={Client1}
                    class="img-fluid border border-0"
                    height={"300"}
                    width={"300"}
                    alt="c1"
                  />
                </span>
                <span >
                  <img
                    src={Client2}
                    class="img-fluid border border-0"
                    height={"300"}
                    width={"300"}
                    alt="c2"
                  />
                </span>
                <span >
                  <img
                    src={Client3}
                    class="img-fluid border border-0"
                    height={"300"}
                    width={"300"}
                    alt="c3"
                  />
                </span>
                <span >
                  <img
                    src={Client4}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c4"
                  />
                </span>
                <span >
                  <img
                    src={Client5}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c5"
                  />
                </span> */}
        </Box>
      </Box>
      {/* <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client1}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c1"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client2}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c2"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client3}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c3"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client4}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c4"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client5}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c5"
                  />
                </Grid> */}
    </Fragment>
  );
}
