import React, { Fragment } from "react";
import "./Footer.css";
import Grid from "@mui/material/Grid2";
import { Typography, Box, useScrollTrigger } from "@mui/material";
import Logo from "../../Assets/Logo (2).png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import { height } from '@mui/system';

export default function Footer() {

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const trigger = useScrollTrigger({ disableHysteresis: true });

  const HomeButton = () => {
    const element = document.getElementById('home');
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const AboutButton = () => {
    const element = document.getElementById('about');
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const USPButton = () => {
    const element = document.getElementById('usp');
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const ServicesButton = () => {
    const element = document.getElementById('services');
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const ContactButton = () => {
    const element = document.getElementById('contact');
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Fragment>
      <Grid container>
        <Box className="footerDataOuterContainer">
          <Box className="footerDataInnerContainer">
            <Grid container spacing={8}>
              {/** Data Section */}
              <Grid size={{ xs: 12, md: 12, lg: 6 }}>
                <Box sx={{ backgroundColor: "none" }}>
                  <img src={Logo}  height='100' width='300' alt="logo" />
                  <Typography variant="subtitle2" color="white">
                    ClovVista is more than simply a cloud operations consulting
                    firm; we are a strategic partner committed to achieving
                    business success with innovative, bespoke solutions.
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "7px",
                  }}
                >
                  <Typography variant="h5" color="white" fontWeight={"bold"}>
                    Company
                  </Typography>
                  <Typography variant="subtitle2" color="white" sx={{ mt: 2 }} className="footerText" onClick={() => { HomeButton() }}>
                    Home
                  </Typography>
                  <Typography variant="subtitle2" color="white" sx={{ mt: 1 }} className="footerText" onClick={() => { AboutButton() }}>
                    About Us
                  </Typography>
                  <Typography variant="subtitle2" color="white" sx={{ mt: 1 }} className="footerText" onClick={() => { USPButton() }}>
                    Why Choose Us
                  </Typography>
                  <Typography variant="subtitle2" color="white" sx={{ mt: 1 }} className="footerText" onClick={() => { ServicesButton() }}>
                    Services
                  </Typography>
                  <Typography variant="subtitle2" color="white" sx={{ mt: 1 }} className="footerText"onClick={() => { ContactButton() }}>
                    Contact
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }}>
                <Box sx={{ backgroundColor: "none", padding: "7px" }}>
                  <Typography variant="h5" color="white" fontWeight={"bold"}>
                    Get Connected
                  </Typography>
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <LocationOnIcon
                      sx={{ color: "white", mr: "8px" }}
                      fontSize="small"
                    />
                    <Typography
                      variant="subtitle2"
                      color="white"
                      sx={{ mt: 1 }}
                    >
                      Ottawa, ON, Canada
                    </Typography>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <EmailIcon
                      sx={{ color: "white", mr: "8px" }}
                      fontSize="small"
                    />
                    <Typography
                      variant="subtitle2"
                      color="white"
                      sx={{ mt: 1 }}
                    >
                      sales@clovvista.com
                    </Typography>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <LocalPhoneIcon
                      sx={{ color: "white", mr: "8px" }}
                      fontSize="small"
                    />
                    <Typography
                      variant="subtitle2"
                      color="white"
                      sx={{ mt: 1 }}
                    >
                      343-254-4596
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* <Divider variant="middle" /> */}

        <Grid size={{ xs: 12, md: 12 }}>
          {/** Copyright Section */}
          <Box className="footerBottomContainer">
            <Box className='footerCopyrightContainer'>
            <Typography variant="caption" color="white" align='center'>
              Copyright © 2024 ClovVista - All Rights Reserved.
            </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
