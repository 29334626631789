import './App.css';
import { Fragment } from 'react';
import HomePage from './Pages/Home/Home'
import ServicesPage from './Pages/Services1/Services1';
// import Routing from './Routing/Routing';

function App() {
  return (
    <Fragment>
      {/* <Routing/> */}
      {/* <ServicesPage/> */}
<HomePage/>
    </Fragment>
  );
}

export default App;
