import { useState, useRef } from "react";
import "./ContactForm.css";
import emailjs from "@emailjs/browser";
import { Box, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

const ContactForm = () => {
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [stateMessage, setStateMessage] = useState(null);

  // const contactForm = document.querySelector("#contact-form");
  const submitBtn = document.querySelector(".submit-btn");
  const nameInput = document.querySelector('#name');
  const emailInput = document.querySelector('#email');
  const messageInput = document.querySelector('#message');

  const publicKey = "njk0tZjadcOnpGXbw";
  const serviceID = "service_cikeqlc";
  const templateID = "template_jte450n";

  const form = useRef();

  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  // contactForm.addEventListener("submit", e => {
  // e.preventDefault();
  const sendEmail = (e) => {
    e.preventDefault();
  submitBtn.innerText = "Just A Moment....";

  const inputFeilds = {
    name: nameInput.value,
    email: emailInput.value,
    message: messageInput.value
  }

  emailjs.sendForm(serviceID, templateID, form.current, {
    publicKey: publicKey }, inputFeilds)
        
    .then(() => {
      submitBtn.innerText = "Message Sent Successfully";

      nameInput.value = "";
        emailInput.value = "";
        messageInput.value = "";

    }, (error) => {
      console.log(error)
      submitBtn.innerText = "Something Went Wrong";
    }
  )
};

  // const sendEmail = (e) => {
  //   e.persist();
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   emailjs
  //     .sendForm(
  //       process.env.serviceID,
  //       process.env.templateID,
  //       e.target,
  //       process.env.publicKey
  //     )
  //     .then(
  //       (result) => {
  //         setStateMessage('Message sent!');
  //         setIsSubmitting(false);
  //         setTimeout(() => {
  //           setStateMessage(null);
  //         }, 5000); // hide message after 5 seconds
  //       },
  //       (error) => {
  //         setStateMessage('Something went wrong, please try again later');
  //         setIsSubmitting(false);
  //         setTimeout(() => {
  //           setStateMessage(null);
  //         }, 5000); // hide message after 5 seconds
  //       }
  //     );

  //   // Clears the form after sending the email
  //   e.target.reset();
  // };

  // email.js.init(publicKey);

  // const form = useRef();

  // // const sendEmail = (e) => {
  //   console.log("we came here");
  //   e.preventDefault();

  //   submitBtn.innerText = "Just A Moment...."

  //   emailjs
  //     .sendForm(serviceID, templateID, form.current, {
  //       publicKey: publicKey,
  //     })
  //     .then(() => {
  //       submitBtn.innerText = "Message Sent Successfully";
  //       nameInput.value = "";
  //       emailInput.value = "";
  //       messageInput.value = "";
  //     })
  // };

  return (
    <Box className="formContainer">
      <form
        id="contactForm"
        ref={form}
        noValidate
        autoComplete="off"
        onSubmit={sendEmail}
      >
        <Grid container spacing={4}>
          <Grid size={{ xs: 12, md: 6 }}>
            {/* <TextField required
              // sx={{ width: "100%" }}
              type="text"
              name="name"
              // error
              fullWidth
              id="outlined-required"
              label="Name"
              placeholder="Enter Name......"
            /> */}
            <input id="name" required type="text" name="name" placeholder="Your Name...."/>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            {/* <TextField required
              // sx={{ width: "100%" }}
              type="email"
              name="email"
              fullWidth
              id="outlined-required"
              label="Email"
              placeholder="Enter Email......"
            /> */}
            <input id="email" required type="text" name="email" placeholder="Your Email...."/>
          </Grid>
          <Grid size={{ xs: 12, md: 12 }}>
            {/* <TextField required
              // sx={{ width: "100%" }}
              type="text"
              name="message"
              fullWidth
              id="outlined-required"
              label="Message"
              placeholder="Enter Your Message..."
              multiline
              rows={6}
            /> */}
            <textarea id="message" required name="message" placeholder="Your Message...."/>
          </Grid>
          <Grid size={{ xs: 12, md: 12 }}>
            {/* <Button variant="contained" color="error" onClick={sendEmail} disabled={isSubmitting}>
              Send A Message
            </Button> */}
            {/* {stateMessage && <p>{stateMessage}</p>} */}
            {/* <input type="submit" value="Send" /> */}
            <button type="submit" class="submit-btn">
              Send A Message
            </button>
          </Grid>
        </Grid>
      </form>
      {/* <form ref={form} onSubmit={sendEmail}>

      <label>Name</label>
      <input type="text" name="name" />
      <label>Email</label>
      <input type="email" name="email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */}
    </Box>
    //   <form onSubmit={sendEmail}>
    //     <label>Name</label>
    //     <input type="text" name="user_name" />
    //     <label>Email</label>
    //     <input type="email" name="user_email" />
    //     <label>Message</label>
    //     <textarea name="message" />
    //     <input type="submit" value="Send" disabled={isSubmitting} />
    //     {stateMessage && <p>{stateMessage}</p>}
    //   </form>
  );
};
export default ContactForm;
