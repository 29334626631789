import * as React from "react";
import "./Navbar.css";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  IconButton,
  Container,
  Button,
  useScrollTrigger,
  Toolbar,
  Drawer,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../Assets/Logo (2).png";
import LogoColored from '../../Assets/logoColored.png'
// import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

// const pages = ["Home", "Who We Are", "Services", "Contact"];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const trigger = useScrollTrigger({ disableHysteresis: true });

  const HomeButton = () => {
    const element = document.getElementById("home");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const USPButton = () => {
    const element = document.getElementById("usp");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const ServicesButton = () => {
    const element = document.getElementById("services");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const ContactButton = () => {
    const element = document.getElementById("contact");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const PricingButton = () => {
    const element = document.getElementById("pricing");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 220 }} role="presentation" onClick={toggleDrawer(false)}>
     <Box sx={{ width:'100%', display:'flex', flexDirection:'row', mb:'20px'  }}>
      <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(false)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Box
            sx={{
              width:'100%',
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img src={LogoColored} href="#home" height={"35"} width={"100"} alt="logo Colored"/>
          </Box>
          </Box> 
      <Button
        className="Button"
        sx={{ color: "#000" }}
        onClick={() => {
          HomeButton();
        }}
      >
        Home
      </Button>
      <Divider />
      
      <Button
        className="Button"
        sx={{ color: "#000" }}
        onClick={() => {
          USPButton();
        }}
      >
        Who We Are
      </Button>
      <Divider />

      <Button
        className="Button"
        sx={{ color: "#000" }}
        onClick={() => {
          ServicesButton();
        }}
      >
        Services
      </Button>
      <Divider />

      <Button
        className="Button"
        sx={{ color: "#000" }}
        onClick={() => {
          ContactButton();
        }}
      >
        Contact
      </Button>
    </Box>
  );

  return (
    // <div style={{width:'80%', backgroundColor:'pink'}}>
    <AppBar
      position="fixed"
      elevation={trigger ? 24 : 0}
      style={{ width:'100%',
        // color: trigger ? "#fff" : "#000",
        backgroundColor: trigger ? "#002129" : "transparent",
        boxShadow: trigger
          ? "5px 0px 27px -5px rgba(0, 0, 0, 0.3) !important"
          : undefined,
      }}
      // sx={{backgroundColor:'transparent', width:'100%'}}
    >
      <Container maxWidth={'xl'} >
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={Logo}
              onClick={() => {
                HomeButton();
              }}
              height="60"
              width={"200"}
              alt="menu Icon"
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {" "}
            {/** Menu For A Small Screen */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer open={open} onClose={toggleDrawer(false)}>
              {DrawerList}
            </Drawer>
          </Box>

          <Box
            sx={{ 
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Logo} href="#home" height={"35"} width={"100"} alt="logo"/>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
            }}
          >
            {" "}
            {/** Menu For A Big Screen */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                justifyContent: {md: 'end', lg:'center'},
              }}
            >
              <Button
                className="Button"
                
                sx={{ color: "#fff",mx: "30px" }}
                onClick={() => {
                  HomeButton();
                }}
              >
                Home
              </Button>
              <Button
                className="Button"
                
                sx={{ color: "#fff", mx: "30px" }}
                onClick={() => {
                  USPButton();
                }}
              >
                Who We Are
              </Button>
              <Button
                className="Button"
                
                sx={{ color: "#fff", mx: "30px" }}
                onClick={() => {
                  ServicesButton();
                }}
              >
                Services
              </Button>
              <Button
                className="Button"
                
                sx={{ color: "#fff", mx: "30px" }}
                onClick={() => {
                  ContactButton();
                }}
              >
                Contact
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <Button
                    variant="contained" color="inherit"
                    // endIcon={<Arr/owOutwardIcon className="homeHeroButtonIcon2"/>}
                    sx={{ 
                      fontSize:'0.8rem'
                    }}
                    onClick={() => { PricingButton() }}
                    >
                    Get a quote
                  </Button>            
                  </Box>
             
        </Toolbar>
      </Container>
    </AppBar>
    // </div>
  );
}
export default ResponsiveAppBar;
