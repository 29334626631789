import React, { Fragment } from "react";
import "./Home.css";
import Navbar from "../../Components/Navbar/Navbar";
// import Carousel from "../../Components/Carousel/Carousel";
import ServiceCard from "../../Components/ServicesCards/ServiceCard";
import Footer from "../../Components/Footer/Footer";
import Booking from "../../Components/CalendlyBooking/Booking";
import PricingPlan from "../../Components/PricingPlan/PricingPlan";
import Clients from "../../Components/Clients/Clients";
import ContactForm from "../../Components/ContactForm/ContactForm";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Button, useScrollTrigger, Chip } from "@mui/material";
import Icon1 from "../../Assets/Customer Insight.png";
import Icon2 from "../../Assets/Cloud Development.png";
import Icon3 from "../../Assets/iMac Settings.png";
import Icon4 from "../../Assets/Management.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
// import Image from "../../Assets/Rectangle 36.png";
import BackgroundImg from "../../Assets/Image1.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router"


export default function Home() {

  // const navigate = useNavigate()

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const trigger = useScrollTrigger({ disableHysteresis: true });

  const ContactButton = () => {
    const element = document.getElementById("contact");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const LearnButton = () => {
    const element = document.getElementById("services");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const clientList = [
  //   {
  //     id: '1',
  //     link: Client1
  //   },
  //   {
  //     id: '2',
  //     link: Client2
  //   },
  //   {
  //     id: '3',
  //     link: Client3
  //   },
  //   {
  //     id: '4',
  //     link: Client4
  //   }
  // ]
  return (
    <Fragment>
      <Navbar />

      <Grid container>
        <Grid
          size={{ xs: 12, md: 12 }}
          id="home"
          sx={{ backgroundColor: "hsla(0, 0%, 0%, 0.521)" }}
        >
          {/* <Carousel /> */}
          <Box className="homeHeroSection">
            <Box className="homeHeroOverlay">
              <Box className="homeImgContainer">
                <img
                  src={BackgroundImg}
                  class="img-fluid"
                  alt="backgroundImage"
                  // style={{position:'relative'}}
                />
              </Box>
              <Box className="homeHeroContent">
                <Box className="homeHeroInnerContainer">
                  <Fade cascade triggerOnce="true">
                    <Chip
                      label="Optimizing Business Performance"
                      // size={{ xs:'small', sm:'small' }}
                      sx={{
                        backgroundColor: "#16939c",
                        color: "white",
                        visibility: { xs: "hidden", md: "visible" },
                      }}
                    />

                    <Typography
                      variant="h3"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      color="white"
                      sx={{ fontSize: { lg: 66, md: 50, xs: 15 } }}
                    >
                      Revolutionizing Your Business Operations
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"open sans"}
                      mt={2}
                      mx={1}
                      color="white"
                      sx={{ fontSize: { sm: 15, xs: 10 } }}
                    >
                      Innovative Strategies To Transform Your Business
                    </Typography>
                    <Box className="homeHeroButtonContainer">
                      {/* <Fade cascade> */}
                      <Button
                        variant="contained"
                        color="error"
                        endIcon={
                          <ArrowForwardIcon
                            className="homeHeroButtonIcon"
                            sx={{ display: { xs: "none", md: "Flex" } }}
                          />
                        }
                        onClick={() => {
                          LearnButton();
                        }}
                        sx={{ fontSize: { xs: "0.5rem", md: "0.8rem" } }}
                      >
                        Learn More
                      </Button>
                      <Button
                        variant="outlined"
                        // color="inherit"
                        endIcon={
                          <ArrowOutwardIcon
                            className="homeHeroButtonIcon2"
                            sx={{ display: { xs: "none", md: "Flex" } }}
                          />
                        }
                        sx={{
                          marginLeft: "2rem",
                          fontSize: { xs: "0.5rem", md: "0.8rem" }, color:'white', border:'1px solid white'
                        }}
                        onClick={() => {
                          ContactButton();
                        }}
                      >
                        Contact Us Now
                      </Button>
                      {/* </Fade> */}
                    </Box>
                  </Fade>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid //ABOUT US SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="about"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="homeAboutOuter">
            <Box className="InnerContainer">
              <Fade cascade triggerOnce="true">
                <Typography
                  variant="subtitle2"
                  fontWeight={"bold"}
                  fontFamily={"open sans"}
                  sx={{ color: "#b19478", padding: "2px" }}
                >
                  ABOUT US
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={"bold"}
                  fontFamily={"open sans"}
                  mt={1}
                >
                  Driving Innovation And Delivering Tailored Solutions
                </Typography>
                <Typography variant="body2" fontFamily={"open sans"} mt={2}>
                  ClovVista is more than simply a consulting firm; we are a
                  strategic partner committed to achieving business success with
                  innovative, bespoke solutions. Our comprehensive process
                  assures a smooth and efficient experience that relates to your
                  long-term objectives.
                </Typography>
                {/* <Typography variant="body2" fontFamily={"open sans"} my={2}>
                At ClovVista, we strive to surpass expectations by encouraging
                cooperation, openness, and a client-centric approach. Our
                comprehensive process assures a smooth and efficient experience
                that relates to your long-term objectives.
              </Typography> */}
              </Fade>
            </Box>
            <Box className="homeAboutClients">
              {/* <Grid size={{ xs: 12, md: 12 }}> */}
              <Clients />
              {/* </Grid> */}
            </Box>
          </Box>
        </Grid>

        <Grid //UNIQUE SELLING POINTS SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="usp"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="homeUSPOuter">
            <Box className="InnerContainer">
              <Typography
                variant="subtitle2"
                fontWeight={"bold"}
                fontFamily={"open sans"}
                sx={{ color: "white" }}
              >
                NEW OPPORTUNITY
              </Typography>
              <Typography
                variant="h4"
                fontWeight={"bold"}
                fontFamily={"open sans"}
                mt={1}
                sx={{ color: "white" }}
              >
                We Build Solutions That Are Bold And <br /> Contemporary
              </Typography>

              <Grid container spacing={2} mt={5}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Box className="homeUSPPoints">
                    <img
                      src={Icon1}
                      height="70"
                      width="70"
                      alt="Our Approach"
                    />

                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={2}
                      color="white"
                    >
                      Our Approach
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={"open sans"}
                      color="white"
                    >
                      At ClovVista, we take a holistic approach to consulting.
                      We work closely with our clients to understand their goals
                      and challenges, and develop solutions that address their
                      specific needs. Our approach is collaborative,
                      transparent, and results-driven.
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Box className="homeUSPPoints">
                    <img
                      src={Icon2}
                      height="70"
                      width="70"
                      alt="Innovative Technology"
                    />
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={2}
                      color="white"
                    >
                      Innovative Technology
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={"open sans"}
                      color="white"
                    >
                      We stay up-to-date with the latest technology trends and
                      offer innovative solutions that help you stay ahead of the
                      competition.
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Box className="homeUSPPoints">
                    <img
                      src={Icon3}
                      height="70"
                      width="70"
                      alt="Our Expertise"
                    />
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={2}
                      color="white"
                    >
                      Our Expertise
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={"open sans"}
                      color="white"
                    >
                      Our team at ClovVista is made up of highly skilled
                      professionals with diverse backgrounds. We work together
                      to provide our clients with tailored solutions that meet
                      their unique needs. Meet our team and learn more about our
                      individual areas of expertise.
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Box className="homeUSPPoints">
                    <img
                      src={Icon4}
                      height="70"
                      width="70"
                      alt="Industry Expertise"
                    />
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={2}
                      color="white"
                    >
                      Industry Expertise
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={"open sans"}
                      color="white"
                    >
                      At ClovVista, we have years of experience in the
                      consulting industry. We specialize in providing top-notch
                      services in areas such as marketing, strategy, and
                      operations. Our expertise can help take your business to
                      the next level.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid //OUR SERVICES SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="services"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="homeServicesOuter">
            <Box className="InnerContainer">
              <Grid container>
                <Grid size={{ xs: 12, md: 12 }}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    sx={{ color: "#b19478" }}
                    align="center"
                  >
                    OUR CONSULTING SERVICES
                  </Typography>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    mt={1} mb={4}
                    sx={{ color: "black" }}
                    align="center"
                  >
                    Providing Strategic Insights and Guidelines to Empower Businesses
                  </Typography>
                </Grid>
                {/* <Grid size={{ xs: 12, md: 2 }}>
                  <Box className='homeSerivesLearnText'>
                  <Typography variant="subtitle2" align='right' className="homeServicesLearn">Learn More</Typography>
                  <ArrowForwardIcon fontSize="small" sx={{marginLeft:'5px'}}/>
                  </Box>
                </Grid> */}
              </Grid>

              <ServiceCard />
            </Box>
          </Box>
        </Grid>

        <Grid //COLORED BAND SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="homeQuoteOuter">
            <Box className="InnerContainer">
              <Fade triggerOnce="true">
                {/* <Typography
                  variant="h4"
                  align="center"
                  fontWeight={"bold"}
                  fontFamily={"open sans"}
                  color="white"
                  sx={{ fontSize: { lg: 35, md: 20, xs: 18 } }}
                >
                  Looking For Top Consulting Solutions To Boost Productivity And
                  Stay Competitive?
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  // fontWeight={"bold"}
                  fontFamily={"open sans"}
                  my={3}
                  color="white"
                >
                  We’re here to help to grow your business.
                </Typography> */}
                <Typography
                variant="subtitle2"
                fontWeight={"bold"}
                fontFamily={"open sans"}
                sx={{ color: "white" }}
                align="center"
              >
                BOOK AN ONLINE APPOINTMENT
              </Typography>
              <Typography
                variant="h4"
                fontWeight={"bold"}
                fontFamily={"open sans"}
                mt={1} 
                // mb={4}
                sx={{ color: "white" }}
                align="center"
              >
                Choose the Perfect Plan for Your Needs and Book an Appointment
                Today
              </Typography>
              <Typography
                  variant="body2"
                  align="center"
                  // fontWeight={"bold"}
                  fontFamily={"open sans"}
                  my={1} mb={4}
                  color="white"
                >
                  We’re here to help to grow your business.
                </Typography>
              <PricingPlan />

                {/* <Box className="homeButtonCnotainer">
                  <Booking />
                </Box> */}
              </Fade>
            </Box>
          </Box>
        </Grid>

        {/* <Grid //PLANS SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="pricing"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="pricingContainer">
            <Box className="InnerContainer">
              <Typography
                variant="subtitle2"
                fontWeight={"bold"}
                fontFamily={"open sans"}
                sx={{ color: "#b19478" }}
                align="center"
              >
                BOOK AN ONLINE APPOINTMENT
              </Typography>
              <Typography
                variant="h4"
                fontWeight={"bold"}
                fontFamily={"open sans"}
                mt={1} mb={4}
                sx={{ color: "white" }}
                align="center"
              >
                Choose the Perfect Plan for Your Needs and Book an Appointment
                Today
              </Typography>
              <PricingPlan />
            </Box>
          </Box>
        </Grid> */}

        <Grid //CONTACT SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="contact"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="homeContactOuter">
            <Box className="InnerContainer">
              <Grid container spacing={8}>
                <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                  {/* <Box className="homeContactInfo"> */}
                  <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    sx={{ color: "#b19478" }}
                    align="left"
                  >
                    GET IN TOUCH
                  </Typography>
                  <Typography
                    variant="h4"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Reach Our Team
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="black"
                  >
                    Send a message through given form, If your enquiry is time
                    sensitive please use below contact details.
                  </Typography>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Hours:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={5}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Monday - Friday
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Saturday - Sunday
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              9AM - 5PM (EST)
                            </Typography>

                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Closed
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Get In Touch:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={7}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              {" "}
                              (343)-254-4596
                            </Typography>
                            {/* <Typography variant="subtitle2" fontFamily={'open sans'}>Saturday-Sunday</Typography> */}
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              sales@clovvista.com
                            </Typography>
                            {/* <Typography variant="subtitle2" fontFamily={'open sans'}>Closed</Typography> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* </Box> */}
                  {/* <Typography
                    variant="h4"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Drop Us A Line!
                  </Typography> */}
                  {/* <ContactForm/> */}
                </Grid>

                <Grid size={{ xs: 12, md: 12, lg: 5 }}>
                  {/* <img src={Image} class="img-fluid" alt="logo"/> */}
                  <Typography
                    variant="h6"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Drop Us A Line!
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="gray"
                  >
                    Feel free to send us a message about anything you might need
                    help with. We would love to hear from you
                  </Typography>
                  <ContactForm />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid //FOOTER SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Footer />
        </Grid>
      </Grid>
      {/* </Fragment> */}
    </Fragment>
  );
}
